<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ 'show': mqShallShowLeftSidebar }">
        <!-- Filters' Card -->
        <b-card>

          <!-- pages -->
          <!-- <div>
            <h6>
              Pages
            </h6>
            <b-form-radio-group v-model="filters.pages" class="categories-radio-group" stacked :disabled="showLoading"
              :options="filterOptions.pages" @change="$emit('pageChanged', filters.pages)" />
          </div> -->

          <!-- Categories -->
          <!-- <div class="filter-title top-filter">
            <h6>
              Famille
            </h6>
            <b-form-radio-group v-model="filters.categories" class="categories-radio-group" stacked
              :disabled="showLoading" :options="filterOptions.categories" @change="triggerFilter" />
          </div> -->

          <h6 class="filter-title top-filter mt-1">
            Catégorie
          </h6>
          <div class="brands">
            <app-collapse class="categor" type="margin">

              <b-form-radio v-model="localFilters.subCategory" name="some-radios" value="Tous"
                @change="triggerFilter">Tous</b-form-radio>

              <app-collapse-item v-for="(item, index) in filterOptions.categories" :key="index"
                :title="item.familyDescription">
                <b-form-radio-group v-model="localFilters.subCategory" class="categories-radio-group" stacked
                  :disabled="showLoading" :options="item.Categories" @change="triggerFilter" />
              </app-collapse-item>
            </app-collapse>
          </div>


          <!-- Multi Range -->
          <!-- <div class="filter-title mt-2">
            <h6>
              Prix
            </h6>
            <b-form-radio-group v-model="filters.priceRangeDefined" class="price-range-defined-radio-group" stacked
              :disabled="showLoading" :options="filterOptions.priceRangeDefined" @change="triggerFilter" />
          </div> -->

          <!-- Ratings -->
          <!-- <div class="filter-title mt-2">
            <h6 class="filter-title">
              Classification
            </h6>

            <b-form-radio-group v-model="filters.ratings" class="price-range-defined-radio-group" stacked
              :disabled="showLoading">
              <b-form-radio v-for="rating in filterOptions.ratings" :key="rating.rating" :value="rating.value"
                @change="ratingClick(rating)">
                <div class="d-flex">
                  <feather-icon v-for="star in 5" :key="star" icon="StarIcon" size="17"
                    :class="[{ 'fill-current': star <= rating.rating }, star <= rating.rating ? 'text-warning' : 'text-muted']" />
                  <span class="ml-25">&amp; up</span>
                </div>
              </b-form-radio>
            </b-form-radio-group>

          </div> -->

          <!-- Brands -->
          <h6 class="filter-title mt-2">
            Fournisseur
          </h6>
          <div class="brands" style="overflow-y: scroll; max-height: 500px;">
            <b-form-radio-group v-model="localFilters.vendors" class="brands-radio-group" stacked :disabled="showLoading"
              :options="filterOptions.vendors" @change="triggerFilter" />
          </div>

        </b-card>
      </div>
    </div>

    <div class="body-content-overlay" :class="{ 'show': mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)" />
  </div>
</template>

<script>

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    showLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localFilters: { ...this.filters }
    }
  },
  methods: {
    triggerFilter() {
      // console.log(ff);
      // this.uncheckRadioIfChecked();
      this.$emit('triggerFilter', this.localFilters);
    },
    // uncheckRadioIfChecked() {
    //   // Get the checked value
    //   const checkedValue = this.filters.subCategory;
    //   // Check if the clicked radio button is already checked
    //   if (checkedValue !== null) {
    //     // Uncheck the radio button
    //     this.filters.subCategory = null;
    //   }
    // },
    ratingClick(data) {
      this.localFilters.ratings = data.rating
      this.triggerFilter();
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';

.categor {
  .card-body {
    padding: 1rem;
  }

  .card-header {
    padding: 0.9rem 1.7rem 0.9rem 0.5rem !important;
  }

  .collapse-title {
    // font-weight: bold;
    font-size: 0.95rem !important;
  }
}
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep .custom-control {
    margin-bottom: 0.75rem;
  }
}

@media (max-width: 769px) {
  .top-filter {
    padding-top: 3rem;
    /* Adjust the margin-top value as needed */
  }
}
</style>

